import { URL_CLIENT } from "@api/doinsport/services/client";
import {_delete, _get, _post, _put} from "@api/doinsport/services/httpService";

const URI = URL_CLIENT + '/comments'

export const deleteClientComment = (id) => _delete(URI + '/' + id);

export const postClientComment = (comment) => _post(URI, comment);

export const putClientComment = (comment) => _put(URI + '/' + comment.id, comment);

export const getClientComments = (clientId) => _get(URL_CLIENT + '/' + clientId + '/comments');
