<template>
  <b-modal
      :id="modalId"
      hide-header
      hide-footer
      hide-header-close
      size="lg"
      body-bg-variant="gray-lighten"
  >
    <b-row class="mb-2" align="center">
      <b-col class="comment-title-label">
     {{ mode === 'create' ? $t('views.client.details.body.right.notes.add-comment') : $t('views.client.details.body.right.notes.edit-comment')}}
      </b-col>
    </b-row>
    <div class="border-bottom-grey"/>
    <b-row class="mt-3">
      <b-col class="field-name-class mb-2" cols="12">
        {{ $t('views.client.details.body.right.notes.message') }}
      </b-col>
      <b-col cols="12">
        <b-textarea
            rows="5"
            size="sm"
            v-model="text"
            required
            class="background-light-blue-inputs"
            :placeholder="$t('views.client.details.body.right.notes.comment-placeholder')"
        >
        </b-textarea>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col align="right">
        <d-button
            :text="'general.actions.save'"
            class="d-btn-sm font-text-title d-btn btn d-btn-danger font-text-title"
            :class="text.trim().length > 0 ? '' : 'disabled'"
            @on:button-click="addComment"
        />
      </b-col>
    </b-row>
  </b-modal>
</template>
<script>
import {postClientComment, putClientComment} from "@api/doinsport/services/client/comment/comment.api";
import { fromIdToIriReference } from "@/utils/form";

export default {
  data: () => ({
    componentKey: 1,
    customer: ' ',
    text: ''
  }),
  props: {
    modalId: {
      type: String,
      default: 'modal-comment-add'
    },
    mode: {
      type: String,
      default: 'create'
    },
    display: {
      type: Boolean,
      default: false,
    },
    comment: {
      type: Object,
      default: () => {},
    }
  },
  watch: {
    display: function () {
      if (this.mode === 'create') {
        this.text = '';
      } else {
        this.text = this.comment.text;
      }
      this.$bvModal.show(this.modalId);
    }
  },
  methods: {
    addComment() {
      if (this.text.trim().length > 0) {
        if (this.mode === 'create') {
          // TODO : GET User from store
          postClientComment({ client: fromIdToIriReference('/clubs/clients', this.$route.params.id), user: JSON.parse(localStorage.getItem('user'))['@id'], text: this.text })
              .then((response) => {
                this.$bvModal.hide(this.modalId);
                this.$emit('on:comment-added');
              })
          ;
        } else {
          putClientComment({ id: this.comment.id, text: this.text })
            .then((response) => {
              this.$bvModal.hide(this.modalId);
              this.$emit('on:comment-added');
            })
          ;
        }
      }
    },
  },
}
</script>
<style scoped>

.comment-title-label {
  font: normal normal 900 18px Avenir;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
}

/deep/ .modal {
  padding-top: 40px
}

/deep/ .modal-content {
  border-radius: 8px;
}
/deep/ .modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  padding: 1rem;
  border-radius: 8px;
}
/deep/ .card {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #C4C8D066;
  border-radius: 8px;
  opacity: 1;
}

/deep/ .card .card-header {
  background-color: #fff;
  padding-top: 8px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 10px;
  border-bottom: unset;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

/deep/ .card .card-body {
  padding-top: 15px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  background-color: transparent;
}
</style>
