<template>
  <b-card
      :class="$store.getters['layout/getInnerWidth'] > 900 ? classes : 'ml-0'"
  >
    <div>
      <b-row>
        <b-col align="left">
          <label class="notes-title"> {{ $t('views.client.details.body.right.notes.title') }} </label>
        </b-col>
        <b-col align="right">
          <span
              @click="addComment"
              class="pointer d-bg-light-blue fa fa-plus"
          ></span>
        </b-col>
      </b-row>
      <div
          class="row border-bottom-grey"
      >
      </div>
      <perfect-scrollbar
          ref="activitiesScroll"
          class="mb-0 pb-0 pt-0 ml-0 mr-0 pr-0 mt-0 container-activities-scroll"
      >
        <div class="pr-2">
          <div v-for="(comment, i) of comments">
            <div :key="'comment-section' + i">
              <b-row no-gutters class="mt-3 mb-2">
                <b-col cols="12">
                  <label class="gray-note-label"> {{ $t('views.client.details.body.right.notes.single-note') }} </label>
                  <label class="ml-2 red-note-date-label">{{ getCreatedAtDate(comment) }}</label>
                </b-col>
                <b-col cols="10">
                  <p @click="updateComment(comment)" class="text-hover">
                    {{ comment.text }}
                  </p>
                </b-col>
                <b-col align="right" cols="2">
                  <span @click="deleteComment(comment)" class="red-note-date-label pointer fa fa-minus"></span>
                </b-col>
              </b-row>
              <div class="border-bottom-grey"></div>
            </div>
          </div>
        </div>
      </perfect-scrollbar>
      <b-row class="mt-3" v-if="comments.length === 0" align="center">
        <b-col>
          {{ $t('views.client.details.body.right.notes.no-comments') }}
        </b-col>
      </b-row>
    </div>
    <comment-modal
        modal-id="comment-modal-id"
        :display="display"
        :mode="mode"
        :comment="comment"
        @on:comment-added="onCommentAdded"
    />
  </b-card>
</template>

<script>
import {deleteClientComment, getClientComments} from "@api/doinsport/services/client/comment/comment.api";
import CommentModal from "@custom/clients/note/CommentModal";

export default {
  components: {CommentModal},
  data: () => ({
    display: false,
    comments: [],
    mode: 'create',
    comment: null,
  }),
  props: {
    classes: {
      type: String,
      default: 'ml-3'
    }
  },
  methods: {
    loadComments() {
      this.comments = [];

      getClientComments(this.$route.params.id)
          .then((response) => {
            this.comments = response.data['hydra:member'];
          })
          .finally(() => {
          })
      ;
    },
    addComment() {
      this.mode = 'create';
      this.display = !this.display;
    },
    updateComment(comment) {
      this.mode = 'update';
      this.comment = comment;
      this.display = !this.display;
    },
    getCreatedAtDate(comment) {
      return this.$moment.utc(comment.createdAt).format('DD/MM/YYYY');
    },
    onCommentAdded() {
      this.loadComments();
    },
    deleteComment(comment) {
      deleteClientComment(comment.id).then((response) => {
        this.loadComments();
      })
    }
  },
  created() {
    this.loadComments();
  }
}
</script>
<style scoped>

.notes-title {
  text-align: left;
  font: normal normal 900 18px Avenir;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
}

.card {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #C4C8D066;
  border-radius: 8px;
  opacity: 1;
}

.container-activities-scroll {
  position: relative;
  max-height: 240px;
}

.card .card-header {
  background-color: #fff;
  padding-top: 8px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 6px;
  border-bottom: unset;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.card .card-body {
  padding-top: 15px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 24px;
  background-color: transparent;
}

.gray-note-label {
  color: #C0C1C6;
}

.red-note-date-label {
  color: #FF0101;
}

.text-hover:hover {
  cursor: pointer;
}
</style>
